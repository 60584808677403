<template>
  <div class="processPicture">
    <el-dialog title="证据图像查看" :visible="dialogVisible" @close="consoleDetail">
      <h2 class="parkingInfo">
        <a class="parkingInfo-on"></a>
        入场图像
      </h2>
      <h2 class="titleM" v-if="picUrlEntry0">全景照片</h2>
      <div class="panoramicPhotos" v-if="picUrlEntry0">
        <div class="picBox">
          <img :src="picUrlEntry0" width="100%" height="100%" @click="showBigPic(picUrlEntry0)" />
        </div>
      </div>
      <div class="panoramicPhotos">
        <div>
          <h5 class="titleM" v-if="picUrlEntry1">车辆照片</h5>
          <div class="picBox" v-if="picUrlEntry1">
            <img :src="picUrlEntry1" width="100%" height="100%" @click="showBigPic(picUrlEntry1)" />
          </div>
        </div>
        <div>
          <h5 class="titleM" v-if="picUrlEntry2">车牌照片</h5>
          <div class="picBox" v-if="picUrlEntry2">
            <img :src="picUrlEntry2" width="100%" height="100%" @click="showBigPic(picUrlEntry2)" />
          </div>
        </div>
        <!-- <div>
          <h5 class="titleM">入泊视频</h5>
          <div class="picBox">
            <video
              width="100%"
              height="100%"
              controls
              style="background: black"
            >
              <source
                src="http://vjs.zencdn.net/v/oceans.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div> -->
      </div>
      <div
        v-if="picUrlEntry0 == '' && picUrlEntry1 == '' && picUrlEntry2 == ''"
        style="text-align: center"
      >
        暂无照片
      </div>
      <h2 class="parkingInfo">
        <a class="parkingInfo-on"></a>
        出场图像
      </h2>
      <h2 class="titleM" v-if="picUrlExit0">全景照片</h2>
      <div class="panoramicPhotos" v-if="picUrlExit0">
        <div class="picBox">
          <img :src="picUrlExit0" width="100%" height="100%" @click="showBigPic(picUrlExit0)" />
        </div>
      </div>
      <div class="panoramicPhotos">
        <div>
          <h5 class="titleM" v-if="picUrlExit1">车辆照片</h5>
          <div class="picBox" v-if="picUrlExit1">
            <img :src="picUrlExit1" width="100%" height="100%" @click="showBigPic(picUrlExit1)" />
          </div>
        </div>
        <div>
          <h5 class="titleM" v-if="picUrlExit2">车牌照片</h5>
          <div class="picBox" v-if="picUrlExit2">
            <img :src="picUrlExit2" width="100%" height="100%" @click="showBigPic(picUrlExit2)" />
          </div>
        </div>
        <!-- <div>
          <h5 class="titleM">入泊视频</h5>
          <div class="picBox">
            <video
              width="100%"
              height="100%"
              controls
              style="background: black"
            >
              <source
                src="http://vjs.zencdn.net/v/oceans.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div> -->
      </div>
      <div
        v-if="picUrlExit0 == '' && picUrlExit1 == '' && picUrlExit2 == ''"
        style="text-align: center"
      >
        暂无照片
      </div>
      <div class="closewindow">
        <el-button @click="consoleDetail">关闭窗口</el-button>
      </div>
      <transition name="fade">
        <div
          class="mask"
          v-show="bigPic"
          :style="{ 'z-index': '10000', 'background-image': picUrl }"
          @click="bigPic = false"
        ></div>
      </transition>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "picDetail",
  data() {
    return {
      picUrlEntry0: "",
      picUrlEntry1: "",
      picUrlEntry2: "",
      picUrlExit0: "",
      picUrlExit1: "",
      picUrlExit2: "",
      bigPic: false,
      picUrl:
        "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
      picUrl1:
        "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
    };
  },
  watch: {
    parkRecordIdData: {
      handler(curVal, oldVal) {},
      deep: true,
    },
  },
  mounted() {},
  computed: {},
  methods: {
    consoleDetail() {
      console.log("ces");
      this.$emit("consolePicture");
      // this.$emit('update:visible', false)
    },
    showBigPic(url) {
      // if (!url) {
      //   return false;
      // }
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              for (let item in res.value) {
                console.log("1231");
                if (res.value[item].imageType == 1) {
                  this.picUrlEntry0 = res.value[item].picUrl ? res.value[item].picUrl : "";
                }
                if (res.value[item].imageType == 2) {
                  this.picUrlEntry1 = res.value[item].picUrl ? res.value[item].picUrl : "";
                }
                if (res.value[item].imageType == 3) {
                  this.picUrlEntry2 = res.value[item].picUrl ? res.value[item].picUrl : "";
                }
              }
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              for (let item in res.value) {
                console.log("1231");
                if (res.value[item].imageType == 1) {
                  this.picUrlExit0 = res.value[item].picUrl;
                }
                if (res.value[item].imageType == 2) {
                  this.picUrlExit1 = res.value[item].picUrl;
                }
                if (res.value[item].imageType == 3) {
                  this.picUrlExit2 = res.value[item].picUrl;
                }
              }
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
  },
  props: {
    dialogVisible: {
      default: false,
    },
    consolePicture: {
      default: () => {},
    },
    infoSize: {
      type: Number,
      default: 6,
    },
    panoramicPhotos: {
      default: function () {
        return [
          {
            picUrl:
              "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
          },
          {
            picUrl:
              "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
          },
          {
            picUrl:
              "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
          },
          {
            picUrl:
              "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
          },
        ];
      },
    },
  },
  created() {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.closewindow {
  display: flex;
  justify-content: right;
}

.titleM {
  margin-bottom: 10px;
  padding-left: 5px;
}

.panoramicPhotos {
  display: flex;
  margin-bottom: 10px;
}

.picBox {
  // width: 200px;
  height: 150px;
  padding: 5px;
  flex: 1;
}

.parkingInfo {
  font-family: 'PingFang';
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  padding-left: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.parkingInfo-on {
  position: absolute;
  left: 0;
  top: 31%;
  width: 4px;
  height: 16px;
  background: #0099fa;
}
</style>
<style lang="stylus">
.processPicture .el-dialog__body {
  padding-top: 4px;
}

.processPicture .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}
</style>
